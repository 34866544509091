body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "NotoSansKR-Black";
  src: url(./font/Noto_Sans_KR/NotoSansKR-Black.otf);
}

@font-face {
  font-family: "NotoSansKR-Bold";
  src: url(./font/Noto_Sans_KR/NotoSansKR-Bold.otf);
}

@font-face {
  font-family: "NotoSansKR-Light";
  src: url(./font/Noto_Sans_KR/NotoSansKR-Light.otf);
}

@font-face {
  font-family: "NotoSansKR-Medium";
  src: url(./font/Noto_Sans_KR/NotoSansKR-Medium.otf);
}

@font-face {
  font-family: "NotoSansKR-Regular";
  src: url(./font/Noto_Sans_KR/NotoSansKR-Regular.otf);
}

@font-face {
  font-family: "NotoSansKR-Thin";
  src: url(./font/Noto_Sans_KR/NotoSansKR-Thin.otf);
}

@font-face {
  font-family: "NanumGothic";
  src: url(./font/Nanum-Gothic/NanumGothic.otf);
}

@font-face {
  font-family: "NanumGothicBold";
  src: url(./font/Nanum-Gothic/NanumGothicBold.otf);
}

@font-face {
  font-family: "NanumGothicExtraBold";
  src: url(./font/Nanum-Gothic/NanumGothicExtraBold.otf);
}

@font-face {
  font-family: "NanumGothicLight";
  src: url(./font/Nanum-Gothic/NanumGothicLight.otf);
}