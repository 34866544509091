@charset "utf-8";
/* CSS Document */

.accordion {
    z-index: 2;
    padding: 5px 0;
    width: 100%;
    background-color: linear-gradient(to right, rgb(14, 168, 230),rgb(1, 192, 215));
  }

  details {
    cursor: pointer;
    width: 100%;
  }

  details:hover {
    filter: invert(33%) sepia(100%) saturate(592%) hue-rotate(208deg) brightness(106%) contrast(114%);
  }

  .noDetails a {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    font-size: 16px;
    font-family: "NotoSansKR-Regular";
    color: white;
  }

  .noDetails a:hover {
    filter: invert(33%) sepia(100%) saturate(592%) hue-rotate(208deg) brightness(106%) contrast(114%);
  }

  details summary {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    font-size: 16px;
    font-family: "NotoSansKR-Regular";
    color: white;
  }

  details summary::marker {
    font-size: 0px;
    display: none;
  }

  details summary::-webkit-details-marker{
    display: none;
  }

  .content-wrapper {
    overflow: hidden;
    text-align: left;
    padding-left: 40px;
    margin-top: -5px;
    font-size: 16px;
    font-family: "NotoSansKR-Regular";
  }

  .content {
    transition: 0.3s ease;
    margin-top: -75px;
    opacity: 0;
  }

  .content a {
    cursor: pointer;
    display: block;
    font-size: 16px;
    font-family: "NotoSansKR-Regular";
    color: white;
  }

  .content a:hover {
    filter: invert(33%) sepia(100%) saturate(592%) hue-rotate(208deg) brightness(106%) contrast(114%);
  }

  .noContent {
    margin-top: 10px;
  }

  details[open] + .content-wrapper > .content {
    margin-top: 10px;
    opacity: 1;
  }

  .arrow {
    transition: transform 0.3s;
    width: 10px;
    height: 10px;
    margin-right: 25px;
    border-top: 2px solid white;
    border-right: 2px solid white;
    transform: rotate(135deg);
  }

  details[open] .arrow {
    transform: rotate(315deg);
  }
