@charset "utf-8";
/* CSS Document */

/* hom0002m - 이용약관 */
.hom0002m .rule {
    width: 1180px;
    margin: 0 auto;
    padding: 20px 30px 0;
    box-sizing: border-box;
    text-align: left;
    margin-top: 64px;
}
.hom0002m .rule .rule_header {
    height: 30px;
    border-bottom: 2px solid #222;
}
.hom0002m .rule .rule_header h1 {
    font-size: 18px;
    color: #333;
}
.hom0002m .rule .rule_body {
    padding: 20px 0;
}
.hom0002m .rule .rule_body .txt_infobox {
    margin: 40px 30px 30px;
    color: #222;
    line-height: 21px;
}
.hom0002m .rule .rule_body .rule_list {
    margin: 0 0 40px 0;
    padding: 0 30px;
}
.hom0002m .rule .rule_body .rule_list table {
    table-layout: fixed;
    width: 100%;
}
.hom0002m .rule .rule_body .rule_list td {
    vertical-align: top;
    padding: 8px 0 8px 15px;
    border: 1px solid rgb(236, 246, 255);
}
.hom0002m .rule .rule_body .rule_list td a {
    display: block;
    min-height: 25px;
    padding: 2px 0 0;
    color: rgb(6, 128, 185);
    line-height: 27px;
    font-weight: bold;
    background-color: transparent;
}
.hom0002m .rule .rule_body .text dt {
    height: 38px;
    padding: 1px 0 0 17px;
    color: rgb(6, 128, 185);
    line-height: 38px;
    font-weight: bold;
    border-top: 1px solid rgb(6, 128, 185);
    background-color: rgb(236, 246, 255);
}
.hom0002m .rule .rule_body .text dt .txt_header {
    display: inline-block;
    padding: 0 0 0 15px;
}
.hom0002m .rule .rule_body .text dd {
    padding: 16px 40px 24px;
    color: #222;
    line-height: 23px;
}
.hom0002m .rule .rule_body .text dd a {
    color: #333;
}
.hom0002m .rule .rule_body .btn_rule_top {
    position: fixed;
    right: 10px;
    bottom: 110px;
    color: rgb(6, 128, 185, 0.5);
}


/* hom0003m - 개인정보취급방침 */
.hom0003m .rule {
    width: 1180px;
    margin: 0 auto;
    padding: 20px 30px 0;
    box-sizing: border-box;
    text-align: left;
    margin-top: 64px;
}
.hom0003m .rule .rule_header {
    height: 30px;
    border-bottom: 2px solid #222;
}
.hom0003m .rule .rule_header h1 {
    font-size: 18px;
    color: #333;
}
.hom0003m .rule .rule_body {
    padding: 20px 0;
}
.hom0003m .rule .rule_body .txt_infobox {
    margin: 40px 30px 30px;
    color: #222;
    line-height: 21px;
}
.hom0003m .rule .rule_body .rule_list {
    margin: 0 0 40px 0;
    padding: 0 30px;
}
.hom0003m .rule .rule_body .rule_list table {
    table-layout: fixed;
    width: 100%;
}
.hom0003m .rule .rule_body .rule_list td {
    vertical-align: top;
    padding: 8px 0 8px 15px;
    border: 1px solid rgb(236, 246, 255);
}
.hom0003m .rule .rule_body .rule_list td a {
    display: block;
    min-height: 25px;
    padding: 2px 0 0;
    color: rgb(6, 128, 185);
    line-height: 27px;
    font-weight: bold;
    background-color: transparent;
}
.hom0003m .rule .rule_body .text dt {
    height: 38px;
    padding: 1px 0 0 17px;
    color: rgb(6, 128, 185);
    line-height: 38px;
    font-weight: bold;
    border-top: 1px solid rgb(6, 128, 185);
    background-color: rgb(236, 246, 255);
}
.hom0003m .rule .rule_body .text dt .txt_header {
    display: inline-block;
    padding: 0 0 0 15px;

}
.hom0003m .rule .rule_body .text dd {
    padding: 16px 40px 24px;
    color: #222;
    line-height: 23px;
}
.hom0003m .rule .rule_body .text dd a {
    color: #333;
}
.hom0003m .rule .rule_body .btn_rule_top {
    position: fixed;
    right: 10px;
    bottom: 110px;
    color: rgb(6, 128, 185, 0.5);
}


/* hom0004m - 이메일무단수집거부 */
.hom0004m {
    height: 800px;
}
.hom0004m .rule {
    width: 1180px;
    margin: 0 auto;
    padding: 20px 30px 0;
    box-sizing: border-box;
    text-align: left;
    margin-top: 64px;
}
.hom0004m .rule .rule_header {
    height: 30px;
    border-bottom: 2px solid #222;
}
.hom0004m .rule .rule_header h1 {
    font-size: 18px;
    color: #333;
}
.hom0004m .rule .rule_body {
    padding: 20px 0;
}
.hom0004m .rule .rule_body .txt_infobox {
    margin: 40px 30px 30px;
    color: #222;
    line-height: 21px;
}
.hom0004m .rule .rule_body .text dt {
    height: 38px;
    padding: 1px 0 0 17px;
    color: rgb(6, 128, 185);
    line-height: 38px;
    font-weight: bold;
    border-top: 1px solid rgb(6, 128, 185);
    background-color: rgb(236, 246, 255);
}
.hom0004m .rule .rule_body .text .txt_header {
    display: inline-block;
    padding: 0 0 0 15px;
}
.hom0004m .rule .rule_body .text dd {
    padding: 16px 40px 24px;
    color: #222;
    line-height: 23px;
}
.hom0004m .rule .rule_body .text dd a {
    color: #333;

}