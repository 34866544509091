@charset "utf-8";
/* CSS Document */

/******************
* Version      웹
*******************/

.hom0101m_W .title_1 {
    width: 1180px;
    margin: 0 auto;
    padding: 30px 0px 0;
    box-sizing: border-box;
    text-align: left;    
    margin-top:64px;
}
.hom0101m_W .title_1 h3 {
    color: #454545;
    font-size: 28px;
    font-weight: 600;
    height: 28px;
    line-height: 28px;
    border-left: 8px solid rgb(14, 168, 212); 
    font-family: "NotoSansKR-regular";
}
.hom0101m_W .body {
    width: 1180px;
    margin: 0 auto;
    padding: 20px 30px 0;
    box-sizing: border-box;
    text-align: left; 
    margin-top: 20px;
}
.hom0101m_W .body .greeting {
    width: 1180px;
    display: flex;
    justify-content: space-between;
}
.hom0101m_W .body .greeting .greeting_img_area {
    width: 300px;
    margin-bottom: 40px;
}
.hom0101m_W .body .greeting .greeting_img_area img {    
    text-align: left;
    width: 470px;
    height: 528px;
    object-fit: cover;
}
.hom0101m_W .body .greeting .greeting_txt_area {
    /* width: calc(100% - 580px); */
    margin-top: -20px;
}
.hom0101m_W .body .greeting .greeting_txt_area .greeting_txt {
    font-family: 'NotoSansKR-regular';
    font-size: 16px;
    color: black;
    /* line-height: 28px; */
    line-height: 35px;
    word-break: keep-all;
    margin-left: 0px;
    /* margin: 0 100px; */
}
.hom0101m_W .body .greeting .greeting_txt_area .greeting_txt_big {
    font-family: 'NotoSansKR-bold';
    color: #454545;
    margin-inline-start: 35px;
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    margin-top: 50px;
    word-break: keep-all;
    padding: 20px 0;
}
.hom0101m_W .body .greeting .greeting_txt_area .greeting_txt_big b {
    font-size: 24px;
    color: #003b83;
}
.hom0101m_W .body .title_2 {
    width: 1180px;
    margin: 0 auto;
    padding: 30px 0px 0;
    box-sizing: border-box;
    text-align: left;
}
.hom0101m_W .body .title_2 h3 {
    color: #454545;
    font-size: 28px;
    font-weight: 600;
    height: 28px;
    line-height: 28px;
    border-left: 8px solid rgb(14, 168, 212); 
    font-family: "NotoSansKR-regular";
}
.hom0101m_W .body .title_2 table {
    width: 100%;
    border-top: 2px solid rgb(6, 128, 185);
    border-collapse : collapse;
}
.hom0101m_W .body .title_2 table th {
    border-right: 1px solid rgb(6, 128, 185, 0.5);
    border-bottom: 1px solid rgb(6, 128, 185, 0.5);
    padding: 15px;
    /* background: #4ab2e2; */
    background: #4ab2e2;
    font-family: "NotoSansKR-regular";
    font-size: 15px;
    font-weight: 500;
    color: white;
}
.hom0101m_W .body .title_2 table td {
    border-right: 1px solid rgb(6, 128, 185, 0.5);
    border-bottom: 1px solid rgb(6, 128, 185, 0.5);
    padding: 15px;
    font-size: 15px;
    color: black;
    font-family: "NotoSansKR-regular";
}
.hom0101m_W .body .title_2 table td b {
    color: #343434;
    font-weight: normal;
}
.hom0101m_W .body .title_2 table td:last-child {
    border-right: 0;
}
.hom0101m_W .body .title_3 {
    width: 1180px;
    margin: 0 auto;
    padding: 30px 0px 0;
    box-sizing: border-box;
    text-align: left;
}
.hom0101m_W .body .title_3 h3 {
    color: #454545;
    font-size: 28px;
    font-weight: 600;
    height: 28px;
    line-height: 28px;
    border-left: 8px solid rgb(14, 168, 212); 
    font-family: "NotoSansKR-regular";
}
.hom0101m_W .body .title_3 .title_3_img_area{
    margin: 0 auto;
    text-align: center;    
}
.hom0101m_W .body .title_3 .title_3_img_area img{
    text-align: center;
     /* width: 900px; */
    width: 80%;
    object-fit: cover;
    padding-bottom: 40px;
}

.hom0101m_W .body .title_4 {
    width: 1180px;
    margin: 0 auto;
    padding: 30px 0px 0;
    box-sizing: border-box;
    text-align: left;
}
.hom0101m_W .body .title_4 h3 {
    color: #454545;
    font-size: 28px;
    font-weight: 600;
    height: 28px;
    line-height: 28px;
    border-left: 8px solid rgb(14, 168, 212); 
    font-family: "NotoSansKR-regular";
}
.hom0101m_W .body .title_4 .title_4_img_area{
    margin: 0 auto;
    text-align: center;    
}
.hom0101m_W .body .title_4 .title_4_img_area img{
    text-align: center;
    width: 80%;
    object-fit: cover;
    padding-bottom: 40px;
}



/* hom0102m - 오시는길 */
.hom0102m_W {
    height: 800px;
}
.hom0102m_W .title {
    width: 1180px;
    margin: 0 auto;
    padding: 30px 30px 0;
    box-sizing: border-box;
    text-align: left;
    margin-top: 64px;
}
.hom0102m_W .title h3 {
    color: #454545;
    font-size: 28px;
    font-weight: 600;
    height: 28px;
    line-height: 28px;
    border-left: 8px solid rgb(14, 168, 212);
    font-family: "NotoSansKR-regular";
}
.hom0102m_W .body .txt {
    width: 1180px;
    font-family: "NotoSansKR-regular";
    margin: 0 auto;
    padding: 0px 30px 0;
    box-sizing: border-box;
    text-align: left;
    margin-top: 20px;
}
.hom0102m_W .body .txt ul {
    padding-left: 0px;
}
.hom0102m_W .body .txt li {
    margin: 5px;
}
.hom0102m_W .body .txt li span {
    display: inline-block;
    font-family: "NotoSansKR-regular";
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    color: #fff;
    background: #0082c2;
    border-radius: 25px;
    width: 80px;
    height: 23px;
    line-height: 23px;
    margin-right: 10px;
}
.hom0102m_W .body .txt b {
    font-family: "NotoSansKR-bold";
    font-size: 24px;
    color: #003b83;
    font-weight: 700;
}
.hom0102m_W .body .txt p {
    font-family: "NotoSansKR-bold";
    color: #454545;
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    margin-top: 30px;
    word-break: keep-all;
    padding: 20px 0;
}
.hom0102m_W .body .kakaoMap {
    z-index: 1;
    width: 1180px;
    margin: 0 auto;
    padding: 20px 30px 0;
    box-sizing: border-box;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 40px;
}
.kakaoMap_W {
    width: 1180px;
    height: 400px;
}


/* hom0103m - 사업분야 */
.hom0103m_W {
    height: 1300px;
}
.hom0103m_W .title {
    width: 1180px;
    margin: 0 auto;
    padding: 30px 30px 0;
    box-sizing: border-box;
    text-align: left; 
    margin-top: 64px;
}
.hom0103m_W .title h3 {
    color: #454545;
    font-size: 28px;
    font-weight: 600;
    height: 28px;
    line-height: 28px;
    border-left: 8px solid rgb(14, 168, 212); 
    font-family: "NotoSansKR-regular";
}
.hom0103m_W .body {
    width: 1180px;
    margin: 0 auto;
    padding: 5px 30px 0;
    box-sizing: border-box;
    text-align: left; 
    margin-top: 20px;
}
.hom0103m_W .body p {
    font-family: "NotoSansKR-bold";
    color: #454545;
    font-size: 18px;
    line-height: 26px;
    margin-top: 15px;
    word-break: keep-all;
}
.hom0103m_W .body b {
    font-family: "NotoSansKR-bold";
    font-size: 20px;
    color: #003b83;
}
.hom0103m_W .body .img_area {
    padding-top: 10px;
    height: 360px;
    text-align: center;
    margin-bottom: 60px;
}
.hom0103m_W .body .img_area img {
    height: 450px;
}
.hom0103m_W .body .box_area {
    padding-top: 40px;
    margin-top: 40px;
}
.hom0103m_W .body .box_area table {
    border-collapse: separate;
    border-spacing: 0 10px;
}
.hom0103m_W .body .box_area th {
    width: calc(30%);
    font-family: "NotoSansKR-regular";
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
    text-align: center;
    color: #4ab2e2;
    border: 2px solid #4ab2e2;
    /* color: #44abc8;
    border: 2px solid #44abc8; */
    border-radius: 30px;
    background: linear-gradient(to top, #f4f4f4, #fff);
    padding: 20px 0;
}
.hom0103m_W .body .box_area td {
    font-family: "NotoSansKR-regular";
    font-size: 16px;
    padding-left: 5%;
}



/* hom0201m - 사업실적 */
.hom0201m_W {
    height: 800px;
}
.hom0201m_W .title {
    width: 1180px;
    margin: 0 auto;
    padding: 30px 30px 0;
    box-sizing: border-box;
    text-align: left;
    margin-top: 64px;
}
.hom0201m_W .title h3 {
    color: #454545;
    font-size: 28px;
    font-weight: 600;
    height: 28px;
    line-height: 28px;
    border-left: 8px solid rgb(14, 168, 212);
    font-family: "NotoSansKR-regular";
}
.hom0201m_W .body {
    width: 1180px;
    margin: 0 auto;
    padding: 20px 30px 0;
    box-sizing: border-box;
    text-align: left;
    margin-top: 20px;
}
.hom0201m_W .body .contents {
    width: 100%;
    margin: 0;
}
.hom0201m_W .body .contents .board_top {
    width: 100%;
    overflow: hidden;
}
.hom0201m_W .body .contents .board_top p {
    float: left;
    width: 200px;
    margin: 0px 0;
    font-family: "NotoSansKR-regular";
}
.hom0201m_W .body .contents .board_top .search {
    float: right;
    width: 330px;
    text-align: right;
    margin-bottom: 10px;
}
.hom0201m_W .body .contents .board {
    width: 100%;
    border-top: 2px solid rgb(6, 128, 185);
}
.hom0201m_W .body .contents .pagination {
    margin: 20px 0;
    padding: 0 0 30px 0;
    text-align: center;
    line-height: normal;
}


/* hom0202m - 사업실적 상세 */
.hom0202m_W {
    height : 800px;
}
.hom0202m_W .title {
    width: 1180px;
    margin: 0 auto;
    padding: 30px 30px 0;
    box-sizing: border-box;
    text-align: left; 
    margin-top: 64px;
}
.hom0202m_W .title h3 {
    color: #454545;
    font-size: 28px;
    font-weight: 600;
    height: 28px;
    line-height: 28px;
    border-left: 8px solid rgb(14, 168, 212); 
    font-family: "NotoSansKR-regular";
} 
.hom0202m_W .body {
    width: 1180px;
    margin: 0 auto;
    padding: 20px 30px 0;
    box-sizing: border-box;
    text-align: left; 
    margin-top: 20px;
}
.hom0202m_W .body table {
    width: 100%;
    border-top: 2px solid rgb(6, 128, 185);
    border-collapse : collapse;
}
.hom0202m_W .body table th {
    background: #4ab2e2;
    font-family: "NotoSansKR-regular";
    font-size: 15px;
    font-weight: 500;
    color: white;
    border-right: 1px solid rgb(6, 128, 185, 0.5);
    border-top: 1px solid rgb(6, 128, 185, 0.5);
    padding: 20px;
}
.hom0202m_W .body table td {
    border-right: 1px solid rgb(6, 128, 185, 0.5);
    border-bottom: 1px solid rgb(6, 128, 185, 0.5);
    border-top: 1px solid rgb(6, 128, 185, 0.5);
    padding: 20px;
    font-size: 15px;
    color: black;
    font-family: "NotoSansKR-regular";
}
.hom0202m_W .body table td b{
    color: #343434;
    font-weight: normal;
}
.hom0202m_W .body table td:last-child{
    border-right: 0;
}


/* hom0203m - 고객사/협력사 */
.hom0203m_W {
    height: 1000px;
}
.hom0203m_W .title {
    width: 1180px;
    margin: 0 auto;
    padding: 30px 30px 0;
    box-sizing: border-box;
    text-align: left;
    margin-top: 64px;
}
.hom0203m_W .title h3 {
    color: #454545;
    font-size: 28px;
    font-weight: 600;
    height: 28px;
    line-height: 28px;
    border-left: 8px solid rgb(14, 168, 212); 
    font-family: "NotoSansKR-regular";
}
.hom0203m_W .body {
    width: 1180px;
    margin: 0 auto;
    padding: 0px 30px 0;
    box-sizing: border-box;
    text-align: center; 
    margin-top: 40px;
    padding-bottom: 60px;
}


/* hom0301m - 회사소식 */
.hom0301m_W {
    height: 800px;
}
.hom0301m_W .title {
    width: 1180px;
    margin: 0 auto;
    padding: 30px 30px 0;
    box-sizing: border-box;
    text-align: left;   
    margin-top: 64px;   
}
.hom0301m_W .title h3 {
    color: #454545;
    font-size: 28px;
    font-weight: 600;
    height: 28px;
    line-height: 28px;
    border-left: 8px solid rgb(14, 168, 212);
    font-family: "NotoSansKR-regular";
}
.hom0301m_W .body {
    width: 1180px;
    margin: 0 auto;
    padding: 20px 30px 0;
    box-sizing: border-box;
    text-align: left;
    margin-top: 20px;
}
.hom0301m_W .body .contents {
    width: 100%;
    margin: 0;
}
.hom0301m_W .body .contents .board_top {
    width: 100%;
    overflow: hidden;
}
.hom0301m_W .body .contents .board_top p {
    float: left;
    width: 200px;
    margin: 10px 0;
}
.hom0301m_W .body .contents .board_top .search {
    float: right;
    width: 330px;
    text-align: right;
}
.hom0301m_W .body .contents .board {
    width: 100%;
    border-top: 2px solid rgb(6, 128, 185);
}
.hom0301m_W .body .contents .pagination {
    margin: 20px 0;
    padding: 0px 0 30px 0;
    text-align: center;
    line-height: normal;
}


/* hom0302m - 회사소식 상세 */
.hom0302m_W {
    height : 800px;
}
.hom0302m_W .title {
    width: 1180px;
    margin: 0 auto;
    padding: 30px 30px 0;
    box-sizing: border-box;
    text-align: left; 
    margin-top: 64px;
}
.hom0302m_W .title h3 {
    color: #454545;
    font-size: 28px;
    font-weight: 600;
    height: 28px;
    line-height: 28px;
    border-left: 8px solid rgb(14, 168, 212); 
    font-family: "NotoSansKR-regular";
} 
.hom0302m_W .body {
    width: 1180px;
    margin: 0 auto;
    padding: 20px 30px 0;
    box-sizing: border-box;
    text-align: left; 
    margin-top: 20px;
}
.hom0302m_W .body table {
    width: 100%;
    border-top: 2px solid rgb(6, 128, 185);
    border-collapse : collapse;
}
.hom0302m_W table th {
    background: #4ab2e2;
    font-family: "NotoSansKR-regular";
    font-size: 15px;
    font-weight: 500;
    color: white;
    border-right: 1px solid rgb(6, 128, 185, 0.5);
    border-bottom: 1px solid rgb(6, 128, 185, 0.5);
    border-top: 1px solid rgb(6, 128, 185, 0.5);
    padding: 20px;
}
.hom0302m_W .body table td {
    border-right: 1px solid rgb(6, 128, 185, 0.5);
    border-bottom: 1px solid rgb(6, 128, 185, 0.5);
    border-top: 1px solid rgb(6, 128, 185, 0.5);
    padding: 20px;
    font-size: 15px;
    color: black;
    font-family: "NotoSansKR-regular";
}
.hom0302m_W .body table td b{
    color: #343434;
    font-weight: normal;
}
.hom0302m_W .body table td:last-child{
    border-right: 0;
}


/* hom0303m - 갤러리 */
/* .hom0303m {
    height: 800px;
}
.hom0303m .title {
    width: 1180px;
    margin: 0 auto;
    padding: 30px 30px 0;
    box-sizing: border-box;
    text-align: left;   
    margin-top: 64px;   
}
.hom0303m .title h3 {
    color: #454545;
    font-size: 28px;
    font-weight: 600;
    height: 28px;
    line-height: 28px;
    border-left: 8px solid rgb(14, 168, 212);
    font-family: "NotoSansKR-regular";
}
.hom0303m .body {
    width: 1180px;
    margin: 0 auto;
    padding: 20px 30px 0;
    box-sizing: border-box;
    text-align: left;
    margin-top: 20px;
}
.hom0303m .body .contents {
    width: 100%;
    margin: 0;
}
.hom0303m .body .contents .board_top {
    width: 100%;
    overflow: hidden;
}
.hom0303m .body .contents .board_top p {
    float: left;
    width: 200px;
    margin: 10px 0;
}
.hom0303m .body .contents .board_top .search {
    float: right;
    width: 330px;
    text-align: right;
}
.hom0303m .body .contents .board {
    width: 100%;
    border-top: 2px solid rgb(6, 128, 185);
}
.hom0303m .body .contents .pagination {
    margin: 20px 0;
    padding: 0px 0 30px 0;
    text-align: center;
    line-height: normal;
} */


/* hom0304m - 갤러리 상세 */
/* .hom0304m {
    height : 800px;
}
.hom0304m .title {
    width: 1180px;
    margin: 0 auto;
    padding: 30px 30px 0;
    box-sizing: border-box;
    text-align: left; 
    margin-top: 64px;
}
.hom0304m .title h3 {
    color: #454545;
    font-size: 28px;
    font-weight: 600;
    height: 28px;
    line-height: 28px;
    border-left: 8px solid rgb(14, 168, 212); 
    font-family: "NotoSansKR-regular";
} 
.hom0304m .body {
    width: 1180px;
    margin: 0 auto;
    padding: 20px 30px 0;
    box-sizing: border-box;
    text-align: left; 
    margin-top: 20px;
}
.hom0304m .body table {
    width: 100%;
    border-top: 2px solid rgb(6, 128, 185);
    border-collapse : collapse;
}
.hom0304m table th {
    background: #4ab2e2;
    font-family: "NotoSansKR-regular";
    font-size: 15px;
    font-weight: bold;
    color: rgb(6, 128, 185);
    border-right: 1px solid rgb(6, 128, 185, 0.5);
    border-bottom: 1px solid rgb(6, 128, 185, 0.5);
    border-top: 1px solid rgb(6, 128, 185, 0.5);
    padding: 20px;
}
.hom0304m .body table td {
    border-right: 1px solid rgb(6, 128, 185, 0.5);
    border-bottom: 1px solid rgb(6, 128, 185, 0.5);
    border-top: 1px solid rgb(6, 128, 185, 0.5);
    padding: 20px;
    font-size: 15px;
    color: black;
    font-family: "NotoSansKR-regular";
}
.hom0304m .body table td b{
    color: #343434;
    font-weight: normal;
}
.hom0304m .body table td:last-child{
    border-right: 0;
} */


/* hom0401m - 채용정보 */
.hom0401m_W {
    height: 800px;
}
.hom0401m_W .title {
    width: 1180px;
    margin: 0 auto;
    padding: 30px 30px 0;
    box-sizing: border-box;
    text-align: left;
    margin-top: 64px;
}
.hom0401m_W .title h3 {
    color: #454545;
    font-size: 28px;
    font-weight: 600;
    height: 28px;
    line-height: 28px;
    border-left: 8px solid rgb(14, 168, 212); 
    font-family: "NotoSansKR-regular";
}
.hom0401m_W .body {
    width: 1180px;
    margin: 0 auto;
    padding: 0px 30px 0;
    box-sizing: border-box;
    text-align: left; 
    margin-top: -20px;
}
.hom0401m_W .body .img_area img {
    width: 1180px;
    /* height: 660px; */
}
/* .text_area {
    position: absolute;
    top: 693px;
    left: 1260px;
    color: white;
    font-family: "NotoSansKR-light";
} */


/* hom0402m - 복지제도 */
.hom0402m_W {
    height: 800px;
}
.hom0402m_W .title {
    width: 1180px;
    margin: 0 auto;
    padding: 30px 30px 0;
    box-sizing: border-box;
    text-align: left;
    margin-top: 64px;
}
.hom0402m_W .title h3{
    color: #454545;
    font-size: 28px;
    font-weight: 600;
    height: 28px;
    line-height: 28px;
    border-left: 8px solid rgb(14, 168, 212); 
    font-family: "NotoSansKR-regular";
}
.hom0402m_W .body{
    width: 1180px;
    margin: 0 auto;
    padding: 5px 30px 0;
    box-sizing: border-box;
    text-align: left; 
    margin-top: 20px;
    padding-bottom: 60px;
}
.hom0402m_W .body p {
    font-family: "NotoSansKR-bold";
    color: #454545;
    font-size: 20px;
    line-height: 26px;
    margin-top: 15px;
    word-break: keep-all;
}
.hom0402m_W .body b {
    font-family: "NotoSansKR-bold";
    font-size: 24px;
    color: #003b83;
}
.hom0402m_W .body table {
    width: 100%;
    border-top: 2px solid rgb(6, 128, 185,0.7);
    border-collapse : collapse;
}
.hom0402m_W .body table th {
    border-right: 1px solid rgb(6, 128, 185,0.6);
    border-bottom: 1px solid rgb(6, 128, 185,0.6);
    padding: 10px;
    /* background: #d6f9ff; */
    /* background: rgb(219, 243, 252); */
    background: #4ab2e2;
    font-family: "NotoSansKR-regular";
    font-size: 15px;
    font-weight: 500;
    /* color: #1A75BC; */
    color: white;
}
.hom0402m_W .body table td {
    border-right: 1px solid rgb(6, 128, 185, 0.6);
    border-bottom: 1px solid rgb(6, 128, 185, 0.6);
    padding: 10px;
    font-family: "NotoSansKR-regular";
    font-size: 15px;
    color: black;
}
.hom0402m_W .body table td b {
    color: #343434;
    font-weight: normal;
}
.hom0402m_W .body table td:last-child {
    border-right: 0;
}



/******************
* Version    모바일
*******************/
/* hom0101m - 회사개요 */
.hom0101m_M .title_1 {
    width: 100vw;
    margin: 0 auto;
    padding: 30px 0px 0;
    box-sizing: border-box;
    text-align: left;
    margin-top:64px;
}
.hom0101m_M .title_1 h3 {
    color: #454545;
    /* font-size: 28px; */
    font-size: 20px;
    font-weight: 600;
    height: 28px;
    /* line-height: 28px; */
    line-height: 35px;
    border-left: 8px solid rgb(14, 168, 212); 
    font-family: "NotoSansKR-regular";
}
.hom0101m_M .body {
    width: 100vw;
    margin: 0 auto;
    box-sizing: border-box;
    text-align: left; 
    margin-top: 20px;
}
.hom0101m_M .body .greeting {
    width: 100vw;
}
.hom0101m_M .body .greeting .greeting_img_area {
    width: 300px;
    margin-bottom: 40px;
}
.hom0101m_M .body .greeting .greeting_img_area img {
    width: 100vw;
    height: 422px;
}
.hom0101m_M .body .greeting .greeting_txt_area {
    width: 100vw;
}
.hom0101m_M .body .greeting .greeting_txt_area .greeting_txt {
    font-family: 'NotoSansKR-regular';
    font-size: 10px;
    color: black;
    line-height: 26px;
    word-break: keep-all;
    margin-right: 20px;
    margin-left: 20px;
}
.hom0101m_M .body .greeting .greeting_txt_area .greeting_txt_big {
    font-family: 'NotoSansKR-bold';
    color: #454545;
    margin-inline-start: 35px;
    font-size: 20px;
    line-height: 26px;
    font-weight: 700;
    margin-top: 30px;
    word-break: keep-all;
}
.hom0101m_M .body .greeting .greeting_txt_area .greeting_txt_big b {
    font-size: 24px;
    color: #003b83;
}
.hom0101m_M .body .title_2 {
    width: 100vw;
    margin: 0 auto;
    padding: 30px 0px 0;
    box-sizing: border-box;
    text-align: left;
}
.hom0101m_M .body .title_2 h3 {
    color: #454545;
    /* font-size: 28px; */
    font-size: 17px;
    font-weight: 600;
    height: 28px;
    /* line-height: 28px; */
    border-left: 8px solid rgb(14, 168, 212); 
    font-family: "NotoSansKR-regular";
    margin-left: 20px;
}
.hom0101m_M .body .title_2 table {
    width: 100vw;
    border-top: 2px solid rgb(6, 128, 185);
    border-collapse : collapse;
}
.hom0101m_M .body .title_2 table th {
    border-right: 1px solid rgb(6, 128, 185, 0.5);
    border-bottom: 1px solid rgb(6, 128, 185, 0.5);
    padding: 10px;
    background: #4ab2e2;
    font-family: "NotoSansKR-regular";
    font-size: 10px;
    font-weight: 500;
    color: white;
}
.hom0101m_M .body .title_2 table td {
    border-right: 1px solid rgb(6, 128, 185, 0.5);
    border-bottom: 1px solid rgb(6, 128, 185, 0.5);
    padding: 10px;
    font-size: 10px;
    color: black;
    font-family: "NotoSansKR-regular";
}
.hom0101m_M .body .title_2 table td b {
    color: #343434;
    font-weight: normal;
}
.hom0101m_M .body .title_2 table td:last-child {
    border-right: 0;
}
.hom0101m_M .body .title_3 {
    width: 100vw;
    margin: 0 auto;
    padding: 30px 0px 0;
    box-sizing: border-box;
    text-align: left;
}
.hom0101m_M .body .title_3 h3 {
    color: #454545;
    /* font-size: 28px; */
    font-size: 17px;
    font-weight: 600;
    height: 28px;
    /* line-height: 35px; */
    border-left: 8px solid rgb(14, 168, 212); 
    font-family: "NotoSansKR-regular";
    margin-left: 20px;
}
.hom0101m_M .body .title_3 .title_3_img_area{
    text-align: center;
}
.hom0101m_M .body .title_3 .title_3_img_area img{
    width: 100vw;
    text-align: center;
    object-fit: cover;
    padding-bottom: 40px;
}

.hom0101m_M .body .title_4 {
    width: 100vw;
    margin: 0 auto;
    padding: 30px 0px 0;
    box-sizing: border-box;
    text-align: left;
}
.hom0101m_M .body .title_4 h3 {
    color: #454545;
    /* font-size: 28px; */
    font-size: 17px;
    font-weight: 600;
    height: 28px;
    /* line-height: 35px; */
    border-left: 8px solid rgb(14, 168, 212); 
    font-family: "NotoSansKR-regular";
    margin-left: 20px;
}
.hom0101m_M .body .title_4 .title_4_img_area{
    text-align: center;
}
.hom0101m_M .body .title_4 .title_4_img_area img{
    width: 100vw;
    text-align: center;
    object-fit: cover;
    padding-bottom: 40px;
}

/* hom0102m - 오시는길 */
.hom0102m_M .title {
    width: 100vw;
    margin: 0 auto;
    padding: 20px 20px 0;
    box-sizing: border-box;
    text-align: left;
    margin-top: 64px;
}
.hom0102m_M .title h3 {
    color: #454545;
    font-size: 28px;
    font-weight: 600;
    height: 28px;
    line-height: 28px;
    border-left: 8px solid rgb(14, 168, 212);
    font-family: "NotoSansKR-regular";
}
.hom0102m_M .body .txt {
    width: 100vw;
    font-family: "NotoSansKR-regular";
    margin: 0 auto;
    padding: 0px 20px 0;
    box-sizing: border-box;
    text-align: left;
    margin-top: -10px;
}
.hom0102m_M .body .txt ul {
    padding-left: 0px;
    padding-top: -10px;
}
.hom0102m_M .body .txt li {
    margin: 5px;
}
.hom0102m_M .body .txt li span {
    display: inline-block;
    font-family: "NotoSansKR-regular";
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    color: #fff;
    background: #0082c2;
    border-radius: 25px;
    width: 80px;
    height: 23px;
    line-height: 23px;
    margin-right: 10px;
}
.hom0102m_M .body .txt b {
    font-family: "NotoSansKR-bold";
    font-size: 24px;
    color: #003b83;
    font-weight: 700;
}
.hom0102m_M .body .txt p {
    font-family: "NotoSansKR-bold";
    color: #454545;
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    margin-top: 30px;
    word-break: keep-all;
    padding: 20px 0;
}
.hom0102m_M .body .kakaoMap_M {
    z-index: 1;
    width: 95vw;
    margin: 0 auto;
    padding: 20px 30px 0;
    box-sizing: border-box;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 40px;
}
.kakaoMap_M {
    width: 100vw;
    height: 400px;
}


/* hom0103m - 사업분야 */
.hom0103_M .title {
    width: 100vw;
    margin: 0 auto;
    padding: 20px 20px 0;
    box-sizing: border-box;
    text-align: left;
    margin-top: 64px;
}
.hom0103_M .title h3 {
    color: #454545;
    font-size: 28px;
    font-weight: 600;
    height: 28px;
    line-height: 28px;
    border-left: 8px solid rgb(14, 168, 212); 
    font-family: "NotoSansKR-regular";
}
.hom0103_M .body {
    width: 100vw;
    margin: 0 auto;
    padding: 0;
    box-sizing: border-box;
    text-align: left; 
    margin-top: 20px;
}
.hom0103_M .body .txt_area {
    font-family: "NotoSansKR-bold";
    padding-left: 20px;
    color: #454545;
    font-size: 18px;
    line-height: 26px;
    margin-top: 15px;
    word-break: keep-all;
}
.hom0103_M .body b {
    font-family: "NotoSansKR-bold";
    font-size: 20px;
    color: #003b83;
}
.hom0103_M .body .img_area {
    padding-top: 10px;
    height: 360px;
}
.hom0103_M .body .img_area img {
    width: 100vw;
}
/* .hom0103_M .body .box_area {
    margin-bottom: 40px;
    margin-top: 40px;
} */
.hom0103_M .body .box_area ul {
    width: 100vw;
    margin-top: -100px;
    margin-left: -35px;
    text-align: center;
}
.hom0103_M .body .box_area li {
    width: calc(95%);
    font-family: "NotoSansKR-regular";
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
    text-align: center;
    color: #4ab2e2;
    border: 2px solid #4ab2e2;
    /* color: #44abc8;
    border: 2px solid #44abc8; */
    border-radius: 30px;
    background: linear-gradient(to top, #f4f4f4, #fff);
    padding: 10px 0;
}
.hom0103_M .body .box_area p {
    font-family: "NotoSansKR-regular";
    font-size: 16px;
    padding-left: 5%;
    padding-right: 5%;
    margin-top: 10px;
}




/* hom0201m - 사업실적 */
.hom0201m_M .title {
    width: 100vw;
    margin: 0 auto;
    padding: 20px 20px 0;
    box-sizing: border-box;
    text-align: left;
    margin-top: 64px;
}
.hom0201m_M .title h3 {
    color: #454545;
    font-size: 28px;
    font-weight: 600;
    height: 28px;
    line-height: 28px;
    border-left: 8px solid rgb(14, 168, 212);
    font-family: "NotoSansKR-regular";
}
.hom0201m_M .body {
    width: 100vw;
    margin: 0 auto;
    box-sizing: border-box;
    text-align: left;
    margin-top: 20px;
}
.hom0201m_M .body .contents {
    width: 100vw;
    margin: 0;
}
.hom0201m_M .body .contents .board_top {
    width: 100vw;
    overflow: hidden;
}
.hom0201m_M .body .contents .board_top p {
    float: left;
    width: 200px;
    margin: 0px 0;
    font-family: "NotoSansKR-regular";
}
.hom0201m_M .body .contents .board_top .search {
    float: right;
    width: 330px;
    padding-right: 5px;
    margin-bottom: 10px;
    text-align: right;
}
.hom0201m_M .body .contents .board {
    width: 100vw;
    border-top: 2px solid rgb(6, 128, 185,0.7);
}
.hom0201m_M .body .contents .pagination {
    margin-top: 10px;
    padding: 0 0 30px 0;
    text-align: center;
    line-height: normal;
}


/* hom0202m - 사업실적 상세 */
.hom0202m_M .title {
    width: 100vw;
    margin: 0 auto;
    padding: 20px 20px 0;
    box-sizing: border-box;
    text-align: left; 
    margin-top: 64px;
}
.hom0202m_M .title h3 {
    color: #454545;
    font-size: 28px;
    font-weight: 600;
    height: 28px;
    line-height: 28px;
    border-left: 8px solid rgb(14, 168, 212); 
    font-family: "NotoSansKR-regular";
} 
.hom0202m_M .body {
    width: 100vw;
    margin: 0 auto;
    padding: 10px 5px 0;
    box-sizing: border-box;
    text-align: left; 
    margin-top: 20px;
}
.hom0202m_M .body table {
    width: 100vw;
    border-top: 2px solid rgb(6, 128, 185);
    border-collapse : collapse;
}
.hom0202m_M .body table th {
    background: #4ab2e2;
    font-family: "NotoSansKR-regular";
    font-size: 14px;
    font-weight: 500;
    color: white;
    border-right: 1px solid rgb(6, 128, 185, 0.5);
    border-bottom: 1px solid rgb(6, 128, 185, 0.5);
    border-top: 1px solid rgb(6, 128, 185, 0.5);
    padding: 10px;
    width: 50px;
    text-align: center;
}
.hom0202m_M .body table td {
    border-right: 1px solid rgb(6, 128, 185, 0.5);
    border-bottom: 1px solid rgb(6, 128, 185, 0.5);
    border-top: 1px solid rgb(6, 128, 185, 0.5);
    padding: 10px;
    font-size: 14px;
    color: black;
    font-family: "NotoSansKR-regular";
}
.hom0202m_M .body table td b{
    color: #343434;
    font-weight: normal;
}
.hom0202m_M .body table td:last-child{
    border-right: 0;
}

/* hom0203m - 고객사/협력사 */
.hom0203m_M .title {
    width: 100vw;
    margin: 0 auto;
    padding: 20px 20px 0;
    box-sizing: border-box;
    text-align: left;
    margin-top: 64px;
}
.hom0203m_M .title h3 {
    color: #454545;
    font-size: 28px;
    font-weight: 600;
    height: 28px;
    line-height: 28px;
    border-left: 8px solid rgb(14, 168, 212); 
    font-family: "NotoSansKR-regular";
}
.hom0203m_M .body {
    width: 100vw;
    margin: 0 auto;
    box-sizing: border-box;
    text-align: left; 
    margin-top: 20px;
    /* margin-bottom: 100px; */
}
.hom0203m_M .body .img_area {
    padding-top: 10px;
    /* margin-left: 20px; */
    margin-bottom: 50px;
    height: 360px;
}
.hom0203m_M .body .img_area img {
    width: 100vw;
}




/* hom0301m - 회사소식 */
.hom0301m_M .title {
    width: 100vw;
    margin: 0 auto;
    padding: 20px 20px 0;
    box-sizing: border-box;
    text-align: left;   
    margin-top: 64px;   
}
.hom0301m_M .title h3 {
    color: #454545;
    font-size: 28px;
    font-weight: 600;
    height: 28px;
    line-height: 28px;
    border-left: 8px solid rgb(14, 168, 212);
    font-family: "NotoSansKR-regular";
}
.hom0301m_M .body {
    width: 100vw;
    margin: 0 auto;
    box-sizing: border-box;
    text-align: left;
    margin-top: 20px;
}
.hom0301m_M .body .contents {
    width: 100vw;
    margin: 0;
}
.hom0301m_M .body .contents .board_top {
    width: 100vw;
    overflow: hidden;
}
.hom0301m_M .body .contents .board_top p {
    float: left;
    width: 200px;
    margin: 0px 0;
    font-family: "NotoSansKR-regular";
}
.hom0301m_M .body .contents .board_top .search {
    float: right;
    width: 330px;
    padding-right: 5px;
    margin-bottom: 10px;
    text-align: right;
}
.hom0301m_M .body .contents .board {
    width: 100vw;
    border-top: 2px solid rgb(6, 128, 185);
}
.hom0301m_M .body .contents .pagination {
    margin-top: 10px;
    padding: 0 0 30px 0;
    text-align: center;
    line-height: normal;
}


/* hom0302m - 회사소식 상세 */
.hom0302m_M .title {
    width: 100vw;
    margin: 0 auto;
    padding: 20px 20px 0;
    box-sizing: border-box;
    text-align: left; 
    margin-top: 64px;
}
.hom0302m_M .title h3 {
    color: #454545;
    font-size: 28px;
    font-weight: 600;
    height: 28px;
    line-height: 28px;
    border-left: 8px solid rgb(14, 168, 212); 
    font-family: "NotoSansKR-regular";
} 
.hom0302m_M .body {
    width: 100vw;
    margin: 0 auto;
    padding: 10px 5px 0;
    box-sizing: border-box;
    text-align: left; 
    margin-top: 20px;
}
.hom0302m_M .body table {
    width: 100vw;
    border-top: 2px solid rgb(6, 128, 185);
    border-collapse : collapse;
}
.hom0302m_M table th {
    background: #4ab2e2;
    font-family: "NotoSansKR-regular";
    font-size: 14px;
    font-weight: 500;
    color: white;
    border-right: 1px solid rgb(6, 128, 185, 0.5);
    border-bottom: 1px solid rgb(6, 128, 185, 0.5);
    border-top: 1px solid rgb(6, 128, 185, 0.5);
    padding: 10px;
    width: 50px;
    text-align: center;
}
.hom0302m_M .body table td {
    border-right: 1px solid rgb(6, 128, 185, 0.5);
    border-bottom: 1px solid rgb(6, 128, 185, 0.5);
    border-top: 1px solid rgb(6, 128, 185, 0.5);
    padding: 10px;
    font-size: 14px;
    color: black;
    font-family: "NotoSansKR-regular";
}
.hom0302m_M .body table td b{
    color: #343434;
    font-weight: normal;
}
.hom0302m_M .body table td:last-child{
    border-right: 0;
}


/* hom0401m - 채용정보 */
.hom0401m_M .title {
    width: 100vw;
    margin: 0 auto;
    padding: 20px 20px 0;
    box-sizing: border-box;
    text-align: left;
    margin-top: 64px;
}
.hom0401m_M .title h3 {
    color: #454545;
    font-size: 28px;
    font-weight: 600;
    height: 28px;
    line-height: 28px;
    border-left: 8px solid rgb(14, 168, 212); 
    font-family: "NotoSansKR-regular";
}
.hom0401m_M .body {
    width: 100vw;
    margin: 0 auto;
    box-sizing: border-box;
    text-align: left; 
    margin-top: -20px;
    margin-bottom: 100px;
}
.hom0401m_M .body .img_area img {
    width: 100vw;
}


/* hom0402m - 복지제도 */
.hom0402m_M .title {
    width: 100vw;
    margin: 0 auto;
    padding: 20px 20px 0;
    box-sizing: border-box;
    text-align: left;
    margin-top: 64px;
}
.hom0402m_M .title h3{
    color: #454545;
    font-size: 28px;
    font-weight: 600;
    height: 28px;
    line-height: 28px;
    border-left: 8px solid rgb(14, 168, 212); 
    font-family: "NotoSansKR-regular";
}
.hom0402m_M .body{
    width: 100vw;
    margin: 0 auto;
    padding: 5px 0px 0;
    box-sizing: border-box;
    text-align: left; 
    margin-top: 20px;
    padding-bottom: 60px;
}
.hom0402m_M .body p {
    font-family: "NotoSansKR-bold";
    padding-left: 20px;
    color: #454545;
    font-size: 20px;
    line-height: 26px;
    margin-top: 15px;
    word-break: keep-all;
}
.hom0402m_M .body b {
    font-family: "NotoSansKR-bold";
    font-size: 24px;
    color: #003b83;
}
.hom0402m_M .body table {
    width: 100vw;
    border-top: 2px solid rgb(6, 128, 185);
    border-collapse : collapse;
}
.hom0402m_M .body table th {
    border-right: 1px solid rgb(6, 128, 185, 0.5);
    border-bottom: 1px solid rgb(6, 128, 185, 0.5);
    padding: 10px;
    width: 85px;
    /* background: rgb(219, 243, 252); */
    background: #4ab2e2;
    font-family: "NotoSansKR-regular";
    font-size: 14px;
    font-weight: 500;
    color: white;
}
.hom0402m_M .body table td {
    border-right: 1px solid rgb(6, 128, 185, 0.5);
    border-bottom: 1px solid rgb(6, 128, 185, 0.5);
    padding: 10px;
    font-family: "NotoSansKR-regular";
    font-size: 14px;
    color: black;
}
.hom0402m_M .body table td b {
    color: #343434;
    font-weight: normal;
}
.hom0402m_M .body table td:last-child {
    border-right: 0;
}