.footer {
    width: 100%;
    height: 100px;
    font-family: "NotoSansKR-light";
    color: black;
    background-color: white;
}

.bottomNav {
    width: 100%;
    display: flex;
    margin:0 auto;
}

.bottomNav .naviBox {
    width:70%;
    padding-left: 360px;
}

.bottomNav .naviTos {
    height: 20px;
    display: flex;
    list-style: none;
    text-align: left;
    margin-top: 14px;
    margin-bottom: -5px;
    margin-left: -40px;
    font-size: 13px;
    line-height: 2;
    color: gray;
}

.hyperlink {
    text-decoration : none;
    font-family     : "NanumGothic";
    color: #808080;
}

.bottomNav .naviInfo {
    text-align: left;
    font-size   : 12px;
    line-height : 1.5;
    font-family : "NanumGothic";
    color: #808080;

    display: flex;
    padding-left: 0px;
    margin: 0 auto;
}

.bottomNav .copyright {
    text-align: left;
    font-size   : 12px;
    line-height : 1.5;
    font-family : "NanumGothic";
    color: #808080;
}

.bottomNav .logo_img {
    /* margin-left:150px; */
    margin-top:30px;
    /* width: 34%; */
}

.bottomNav .logo_img img{
    height: 40px;
    float: left;
    margin-bottom: 10px;
}

/* footer 상단 border 라인 */
/* .footer:after {
    width: 100%;
    height: 2px;
    position: absolute;
    content: "";
    left: 0;
    background-color: rgb(6, 128, 185, 0.5);
} */

@media screen and (max-width: 1900px) {
    .bottomNav .naviBox {
        padding-left: 350px;
    }
}

@media screen and (max-width: 1800px) {
    .bottomNav .naviBox {
        padding-left: 300px;
    }
}

@media screen and (max-width: 1700px) {
    .bottomNav .naviBox {
        padding-left: 250px;
    }
}

@media screen and (max-width: 1600px) {
    .bottomNav .naviBox {
        padding-left: 200px;
    }
}

@media screen and (max-width: 1500px) {
    .bottomNav .naviBox {
        padding-left: 150px;
    }
}

@media screen and (max-width: 1400px) {
    .bottomNav .naviBox {
        padding-left: 100px;
    }
}

@media screen and (max-width: 1300px) {
    .bottomNav .naviBox {
        padding-left: 50px;
    }
}

@media screen and (max-width: 1220px) {
    .bottomNav .naviBox {
        padding-left: 15px;
    }
}

@media screen and (max-width: 1100px) {
    .bottomNav {
        display: inline-block;
        width: 100%;
    }

    .bottomNav .naviBox {
        width:100%;
        padding-left: 10px;
    }

    .bottomNav .logo_img {
        margin-top: -10px;
        padding-left: 10px;
    }
}

@media screen and (max-width: 730px) {
    .bottomNav .naviInfo {
        display: block;
    }
}
