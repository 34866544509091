@charset "utf-8";
/* CSS Document */

/******************
* Version      웹
*******************/
.projectBoard_W .projectBoardMain{
    width: 1180px;
    border-bottom: 1px solid rgb(6, 128, 185);
}
.projectBoard_W .projectBoardMain .boardThumb {
    width: 200px;
    height: 80px;
    border: 1px solid #eee;
    text-align: center;
}
.projectBoard_W .projectBoardMain .boardThumb img {
    width: 170px;
    height: 80px;
}
.projectBoard_W .projectBoardMain .boardLeft {
    width: 180px;
    height: 80px;
    text-align: center;
    padding: 5px 0;
    padding-right: 15px;
    vertical-align: middle;
}
.projectBoard_W .projectBoardMain .boardRight {
    width: 900px;
    height: 80px;
    text-align: left;
    padding-left: 15px;
    padding: 5px 0;
    padding-right: 15px;
    vertical-align: middle;
}
.projectBoard_W .projectBoardMain .boardRight .boardCon {
    height: 80px;
}
.projectBoard_W .projectBoardMain .boardRight .boardCon .boardRightTitle {
    font-size: 16px;
    font-weight: 600;
    color: #464646;
    padding-bottom: 5px;
}
.projectBoard_W .projectBoardMain .boardRight .boardCon .boardRightTitle a {
    font-family: "NotoSansKR-regular";
    color: rgb(6, 128, 185);
}
.projectBoard_W .projectBoardMain .boardRight .boardCon .boardRightContents {
    width: 880px;
    vertical-align: top;
    padding-bottom: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.projectBoard_W .projectBoardMain .boardRight .boardCon .boardRightContents a {
    font-size: 13px;
    font-family: "NotoSansKR-regular";
    color: #666666;
}
.projectBoard_W .projectBoardMain .boardRight .boardCon .boardRightWriter {
    font-size: 12px;
    font-family: "NotoSansKR-regular";
    color: #666666;
}


/******************
* Version    모바일
*******************/
.projectBoard_M {
    width: 100vw;
    margin-left: -4px;
}
.projectBoard_M .projectBoardMain{
    width: 100vw;
    border-bottom: 1px solid rgba(97, 97, 254, 0.7);
}
.projectBoard_M .projectBoardMain .boardThumb {
    width: 200px;
    height: 80px;
    border: 1px solid #eee;
    text-align: center;
}
.projectBoard_M .projectBoardMain .boardThumb img {
    width: 170px;
    height: 80px;
}
.projectBoard_M .projectBoardMain .boardLeft {
    width: 100vw;
    height: 80px;
    text-align: center;
    padding: 5px 0;
    vertical-align: middle;
}
.projectBoard_M .projectBoardMain .boardRight {
    width: 100vw;
    height: 80px;
    text-align: left;
    padding: 5px 0;
    vertical-align: middle;
}
.projectBoard_M .projectBoardMain .boardRight .boardCon {
    height: 80px;
}
.projectBoard_M .projectBoardMain .boardRight .boardCon .boardRightTitle {
    width: 97vw;
    font-size: 16px;
    font-weight: 600;
    color: #464646;
    padding-bottom: 5px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.projectBoard_M .projectBoardMain .boardRight .boardCon .boardRightTitle a {
    font-family: "NotoSansKR-regular";
    color: rgb(6, 128, 185);
}
.projectBoard_M .projectBoardMain .boardRight .boardCon .boardRightContents {
    width: 97vw;
    vertical-align: top;
    padding-bottom: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.projectBoard_M .projectBoardMain .boardRight .boardCon .boardRightContents a {
    font-size: 13px;
    font-family: "NotoSansKR-regular";
    color: #666666;
}
.projectBoard_M .projectBoardMain .boardRight .boardCon .boardRightWriter {
    font-size: 12px;
    font-family: "NotoSansKR-regular";
    color: #666666;
}