@charset "utf-8";
/* CSS Document */

/******************
* Version      웹
*******************/
.companyBoard_W .companyBoardMain {
    width: 1180px;
    border-bottom: 1px solid rgb(6, 128, 185);
}
.companyBoard_W .companyBoardMain .boardThumb {
    width: 200px;
    height: 120px;
    border: 1px solid #eee;
    text-align: center;
}
.companyBoard_W .companyBoardMain .boardThumb img{
    width: 170px;
    height: 120px}

.companyBoard_W .companyBoardMain .boardLeft {
    width: 180px;
    text-align: center;
    padding: 15px 0;
    padding-right: 15px;
    vertical-align: middle;
}
.companyBoard_W .companyBoardMain .boardRight {
    height: 120px;
    text-align: left;
    padding-left: 15px;
    padding: 15px 0;
    vertical-align: middle;
}
.companyBoard_W .companyBoardMain .boardRight .boardCon {
    height: 120px;
}
.companyBoard_W .companyBoardMain .boardRight .boardCon .boardRightTitle {
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 10px;
}
.companyBoard_W .companyBoardMain .boardRight .boardCon .boardRightTitle a {
    font-family: "NotoSansKR-regular";
    color: rgb(6, 128, 185);
}
.companyBoard_W .companyBoardMain .boardRight .boardCon .boardRightContents {
    vertical-align: top;
    height: 67px;
    vertical-align: top;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.companyBoard_W .companyBoardMain .boardRight .boardCon .boardRightContents a {
    font-size: 14px;
    font-family: "NotoSansKR-regular";
    color: #666666;
}
.companyBoard_W .companyBoardMain .boardRight .boardCon .boardRightWriter {
    font-size: 12px;
    font-family: "NotoSansKR-regular";
    color: #666666;
    bottom: 0;
}


/******************
* Version    모바일
*******************/
.companyBoard_M {
    width: 100vw;
    margin-left: -4px;
}
.companyBoard_M .companyBoardMain {
    width: 100vw;
    border-bottom: 1px solid rgb(6, 128, 185);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.companyBoard_M .companyBoardMain .boardThumb {
    width: 200px;
    height: 120px;
    border: 1px solid #eee;
    /* text-align: center; */

}
.companyBoard_M .companyBoardMain .boardThumb img{
    width: 200px;
    height: 120px
}

.companyBoard_M .companyBoardMain .boardLeft {
    width: 100vw;
    height: 80px;
    /* text-align: center; */
    padding: 5px 0;
    /* vertical-align: middle; */
    /* display: flex;
    flex-direction: column;
    align-items: center; */
    /* justify-content: center; */
}
.companyBoard_M .companyBoardMain .boardRight {
    height: 100vw;
    height: 80px;
    text-align: left;
    padding: 5px 0;
    vertical-align: middle;
}
.companyBoard_M .companyBoardMain .boardRight .boardCon {
    height: 80px;
}
.companyBoard_M .companyBoardMain .boardRight .boardCon .boardRightTitle {
    width: 97vw;
    font-size: 16px;
    font-weight: 600;
    color: #464646;
    padding-bottom: 5px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.companyBoard_M .companyBoardMain .boardRight .boardCon .boardRightTitle a {
    font-family: "NotoSansKR-regular";
    color: rgb(6, 128, 185);
}
.companyBoard_M .companyBoardMain .boardRight .boardCon .boardRightContents {
    width: 97vw;
    vertical-align: top;
    padding-bottom: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.companyBoard_M .companyBoardMain .boardRight .boardCon .boardRightContents a {
    font-size: 13px;
    font-family: "NotoSansKR-regular";
    color: #666666;
}
.companyBoard_M .companyBoardMain .boardRight .boardCon .boardRightWriter {
    font-size: 12px;
    font-family: "NotoSansKR-regular";
    color: #666666;
    bottom: 0;
}