html { overflow-y:scroll; }

a { text-decoration: none; }

ul, li { list-style: none; }

.header {
    z-index: 3;
    width: 100%;
    height: 64px;
    position: fixed;
    display: flex;
    align-items: center;
    top: 0px;
    left: 0px;
    right: 0px;
    /* background-color: rgb(14, 168, 212); */
    /* background: linear-gradient(to right, rgb(14, 168, 212),rgb(1, 192, 196)); */
    background: linear-gradient(to right, rgb(14, 168, 230),rgb(1, 192, 215));
}

.header_logo {
    width: 30%;
}

.logo_gradation {
    display: inline-block;
    background: transparent;
    text-transform: uppercase;
    letter-spacing: 0.3em;
    border-radius: 0;

    transition: all 0.7s ease-out;
    background: linear-gradient(270deg, rgba(156, 156, 253, 0.8), rgba(134, 132, 253, 0.8), rgba(34,34,34,0), rgba(34,34,34,0));
    background-position: 1% 50%;
    background-size: 300% 300%;
}

/* .logo_gradation:hover {
    background-position: 99% 50%;
} */

.header_menu_1 {
    width: 60%;
    margin-right: 175px;
    text-align: right;
}

.header_menu_1 li {
    position: relative;
    display: inline-block;
    vertical-align: top;
    font-size: 16px;
    font-family: "NotoSansKR-Regular";
}

.header_menu_1 a {
    display: block;
    padding: 18.5px 25px;
    color: white;
}

.header_menu_1 a:after {
    display:block;   /*a요소를 블록 요소라고 선언*/
    /*width:68px;*/      /*카테고리 메뉴 밑줄의 크기를 동일하게 주기 위해 width 설정 */
                    /*혹시 a요소 크기만큼 생기길 바란다면 width 삭제*/
    content: "";
    border-bottom: solid 3px rgba(255, 255, 255, 0.3);
    transform: scaleX(0);   /*크기를 0으로 줌으로써 평상시엔 밑줄 없음*/
    transition: transform 250ms ease-in-out;  /*변형 방식*/
}

.header_menu_1 a:hover:after {
    transform: scaleX(1);  /*a 속성에 hover시 기존 크기로*/
}

.header_menu_1 li:hover .header_menu_2 {
    display: block;
    margin: 0px;
}

/* header 하위 리스트 */
.header_menu_2 {
    width: 115px;
    position: absolute;
    display: none;
    text-align: center;
    margin: 0px;
    padding: 0px;
    line-height: 1.2;
    /* background-color: rgb(5, 187, 200); */
    background-color: rgb(5, 185, 219);
}

.header_menu_2 a {
    padding: 15px 0;
    color: white;
}

.header_toogleBtn {
    position: absolute;
    display: none;
    right: 25px;
    margin-top: 8px;
    color: white;
}

.accordionHEAD {
    display: none;
    width: 100%;
    background: linear-gradient(to right, rgb(14, 168, 230),rgb(1, 192, 215));
}
.accordionHEAD a {
    color: white;
}

@media screen and (max-width: 1100px) {
    .header {
        flex-direction: column;
        align-items: flex-start;
        float: left;
    }

    .header_logo {
        float: center;
        padding: 8px 30px;
    }

    .header_menu_1 {
        display: none;
        flex-direction: column;
        width: 100%;
    }
    
    .header_menu_1 li{ 
        width: 100%;
        text-align: left;
    }

    .header_toogleBtn {
        padding-top: 8px;
        display: block;
    }
    
    .header_menu_1.active {
        display: none;
    }

    .header_menu_2.active {
        display: none;
    }
    
    .accordionHEAD.active {
        display: block;
        width: 100%;
    }
}
